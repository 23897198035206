<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        If an electron in the
        <stemble-latex content="$n=$" />
        <number-value :value="energyLevel1" />
        energy level of a hydrogen atom relaxes down to the
        <stemble-latex content="$n=$" />
        <number-value :value="energyLevel2" />
        energy level, determine the energy of the photon (in kJ) released in the process.
      </p>

      <calculation-input
        v-model="input1"
        prepend-text="$\text{E:}$"
        append-text="$\text{kJ}$"
        show-helpful-hint
        helpful-hint-position="below"
        class="mb-2"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import NumberValue from '../NumberValue';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question17',
  components: {
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: ['input1'],
      input1: null,
    };
  },
  computed: {
    energyLevel1() {
      return this.taskState.getValueBySymbol('energyLevel1').content;
    },
    energyLevel2() {
      return this.taskState.getValueBySymbol('energyLevel2').content;
    },
  },
};
</script>
